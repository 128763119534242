import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { CarList, getCars } from '@api/endpoints/admin'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetCarsAll } from './queryKeys'

export const useQueryGetCars = (): UseQueryResult<CarList> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()
  const query = useQuery({
    queryKey: queryKeyGetCarsAll,
    queryFn: getCars
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
