import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { privateRoutes } from '@constants/routes'
import { VehicleId } from '@api/endpoints'
import { Car, CarList } from '@api/endpoints/admin'
import { Button } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useLanguage } from '@hooks/useLanguage'
import { useQueryGetCars } from '@hooks/ReactQuery/admin'
import { AdminPage } from '@layout/AdminPage'
import { buildAbsolutePath } from '@pages/Admin/Cars/utils'
import styles from '../Admin.module.css'

export const CarsPage: React.FC = () => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const navigate = useNavigate()

  const getCars = useQueryGetCars()

  const isLoading = getCars.isFetching

  const onEditClick = React.useCallback(
    (carId: VehicleId) => {
      navigate(generatePath(privateRoutes.CarsDetail, { carId: carId.toString() }))
    },
    [navigate]
  )
  const onViewPrice = React.useCallback(
    (carId: VehicleId) => {
      window.open(
        buildAbsolutePath(language, generatePath(privateRoutes.CarOffer, { carId: carId.toString() })),
        '_blank'
      )
    },
    [language]
  )

  const onRefreshClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    void getCars.refetch()
  }

  const columns: Array<GridColDef<Car>> = React.useMemo(
    () => [
      { field: 'id', headerName: t('pages.admin.cars.carId'), minWidth: 100 },
      { field: 'registrationNumber', headerName: t('pages.admin.cars.registrationNumber'), minWidth: 150 },
      { field: 'make', headerName: t('pages.admin.cars.make'), minWidth: 100 },
      { field: 'carModel', headerName: t('pages.admin.cars.carModel'), minWidth: 300 },
      { field: 'price', headerName: t('pages.admin.cars.price'), minWidth: 100 },
      { field: 'discountedPrice', headerName: t('pages.admin.cars.discountedPrice'), minWidth: 200 },
      { field: 'isActive', headerName: t('pages.admin.cars.isActive'), minWidth: 50 },
      { field: 'isBooked', headerName: t('pages.admin.cars.isBooked'), minWidth: 150 },
      {
        field: 'actions',
        minWidth: 200,
        headerName: '',
        renderCell: (params) => (
          <div className={styles.tableActions}>
            <Button variant="contained" onClick={() => onEditClick(params.row.id)} disabled={isLoading}>
              {t('global.buttons.edit')}
            </Button>
            <Button variant="contained" onClick={() => onViewPrice(params.row.id)} disabled={isLoading}>
              {t('global.buttons.offer')}
            </Button>
          </div>
        )
      }
    ],
    [t, isLoading, onEditClick, onViewPrice]
  )

  const rows: CarList = React.useMemo(
    () => getCars.data?.map((v) => ({ carModel: v.model + ' ' + (v.modelExtra ?? ''), ...v })) ?? [],
    [getCars.data]
  )

  return (
    <AdminPage>
      <h1>{t('pages.admin.cars.title')}</h1>
      <div className={styles.controlsBar}>
        <Button variant="contained" onClick={onRefreshClick} disabled={isLoading}>
          {t('global.buttons.refresh')}
        </Button>
      </div>
      <div>
        <DataGrid columns={columns} rows={rows} disableRowSelectionOnClick />
      </div>
    </AdminPage>
  )
}
